<template>
  <div class="iot-404"></div>
</template>

<script>
export default {
  name: "notFound",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.iot-404 {
  width: 100%;
  height: 100%;
  background-image: url("https://oss.cniot.fun/iot-system/sys-program/404.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
</style>
